import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnergyTag, OrgRole, UserPropertyType } from './all.enums';
import { Participant, Property, PropertyCount, User } from './all.types';
import { OrgType } from './enums/org.enums';
import { ConstructionPeriod, DisplayParticipantType, EnergyType, ParticipantType } from './enums/property.enums';

export function getOwner(property?: Property | null): User | Participant | undefined {
  return getUserParticipantType(UserPropertyType.Owner, ParticipantType.Owner, property);
}

export function getTenant(property?: Property | null): User | Participant | undefined {
  return getUserParticipantType(UserPropertyType.Tenant, ParticipantType.Tenant, property);
}

export function getUserParticipantType(
  userPropertyType: UserPropertyType,
  participantType: ParticipantType,
  property?: Property | null,
): (User & { userPropertyId?: number }) | Participant | undefined {
  const userProperty = property?.userProperties?.find((up) => up.isMain && up.type === userPropertyType);

  if (userProperty && userProperty.user) {
    const user: User & { userPropertyId?: number } = userProperty?.user;
    user.userPropertyId = userProperty.id;

    return user;
  } else {
    return property?.participants?.find((participant) => participant.type === participantType);
  }
}

export function hasWorkspaces(propertyCounts: PropertyCount[], loggedUser: User): boolean {
  if (loggedUser.isPowerUser) {
    return false;
  }

  const randomOrgProperties = propertyCounts.some((r) => r.org_id > 0);

  if (!randomOrgProperties) {
    return false;
  }

  const personalPropertiesCount = propertyCounts.find((r) => r.org_id === -1)?.count || 0;

  return !(personalPropertiesCount < 1 && propertyCounts.filter((r) => r.org_id > 0).length === 1);
}

export function getAddressLabel(property: Property): string {
  if (!property.address) {
    return '';
  }

  const housenumber = property.address.housenumber !== null ? property.address.housenumber + ' ' : '';

  return `${housenumber}${property.address.street} ${property.address.postcode} ${property.address.city}`;
}

export function hasGas(property: Property): boolean {
  return (
    property.mainEnergyType === EnergyType.TownGas ||
    property.subEnergyType === EnergyType.TownGas ||
    property.waterEnergyType === EnergyType.TownGas
  );
}

export function getConstructionPeriod(constructionYear: number): ConstructionPeriod | null {
  if (constructionYear < 1949) {
    return ConstructionPeriod.Before1949;
  }

  if (constructionYear >= 1949 && constructionYear <= 1960) {
    return ConstructionPeriod.Between1949And1960;
  }

  if (constructionYear >= 1961 && constructionYear <= 1974) {
    return ConstructionPeriod.Between1961And1974;
  }

  if (constructionYear >= 1975 && constructionYear <= 1993) {
    return ConstructionPeriod.Between1975And1993;
  }

  if (constructionYear >= 1994 && constructionYear <= 2000) {
    return ConstructionPeriod.Between1994And2000;
  }

  if (constructionYear >= 2001 && constructionYear <= 2010) {
    return ConstructionPeriod.Between2001And2010;
  }

  if (constructionYear > 2010) {
    return ConstructionPeriod.After2011;
  }

  return null;
}

export function getEnergyTagDpe(
  energy: number | undefined,
  gas: number | undefined,
  isEnergy = true,
  date?: string,
): EnergyTag | undefined {
  const dpeDate = date ? new Date(date) : new Date('2022');
  const toggleDate = new Date('2021-07-01');

  if (
    (!isEnergy && !gas) ||
    (isEnergy && dpeDate < toggleDate && !energy) ||
    (isEnergy && dpeDate >= toggleDate && (!gas || !energy))
  ) {
    return;
  }

  energy = energy as number;
  gas = gas as number;

  if (!isEnergy) {
    if (dpeDate < toggleDate) {
      if (gas <= 5) return EnergyTag.A;

      if (gas >= 6 && gas <= 10) return EnergyTag.B;

      if (gas >= 11 && gas <= 20) return EnergyTag.C;

      if (gas >= 21 && gas <= 35) return EnergyTag.D;

      if (gas >= 36 && gas <= 55) return EnergyTag.E;

      if (gas >= 56 && gas <= 80) return EnergyTag.F;

      return EnergyTag.G;
    } else {
      if (gas < 6) return EnergyTag.A;

      if (gas >= 6 && gas < 11) return EnergyTag.B;

      if (gas >= 11 && gas < 30) return EnergyTag.C;

      if (gas >= 30 && gas < 50) return EnergyTag.D;

      if (gas >= 50 && gas < 70) return EnergyTag.E;

      if (gas >= 70 && gas < 100) return EnergyTag.F;

      return EnergyTag.G;
    }
  }

  if (dpeDate < toggleDate) {
    if (energy <= 50) return EnergyTag.A;

    if (energy >= 51 && energy <= 90) return EnergyTag.B;

    if (energy >= 91 && energy <= 150) return EnergyTag.C;

    if (energy >= 151 && energy <= 230) return EnergyTag.D;

    if (energy >= 231 && energy <= 330) return EnergyTag.E;

    if (energy >= 331 && energy <= 450) return EnergyTag.F;

    return EnergyTag.G;
  } else {
    if (energy < 70 && gas < 6) return EnergyTag.A;

    if ((energy >= 70 && energy < 110 && gas < 11) || (gas >= 6 && gas < 11 && energy < 110)) return EnergyTag.B;

    if ((energy >= 110 && energy < 180 && gas < 30) || (gas >= 11 && gas < 30 && energy < 180)) return EnergyTag.C;

    if ((energy >= 180 && energy < 250 && gas < 50) || (gas >= 30 && gas < 50 && energy < 250)) return EnergyTag.D;

    if ((energy >= 250 && energy < 330 && gas < 70) || (gas >= 50 && gas < 70 && energy < 330)) return EnergyTag.E;

    if ((energy >= 330 && energy < 420 && gas < 100) || (gas >= 70 && gas < 100 && energy < 420)) return EnergyTag.F;

    return EnergyTag.G;
  }
}

export function cleanUserName(user: User | Participant | undefined): string {
  if (!user) {
    return '';
  }

  const firstName = user.firstName || '';
  const lastName = user.lastName || '';

  return `${firstName} ${lastName}`.trim();
}

export function hasError(formGroup: FormGroup, formGroupName: string, formControlName: string): boolean {
  const formControl = (formGroup as FormGroup).get(formGroupName)?.get(formControlName);

  return !!formControl && formControl?.invalid && (formControl?.dirty || formControl?.touched);
}

export function isParticipantEmpty(participant: Participant): boolean {
  return (
    !participant.companyName &&
    !participant.firstName &&
    !participant.lastName &&
    !participant.deskPhone &&
    !participant.mobilePhone
  );
}

export function getParticipantTypeFromUserProperty(
  userPropertyType: UserPropertyType | undefined,
  orgType?: OrgType,
): ParticipantType {
  switch (userPropertyType) {
    case UserPropertyType.Owner:
      return ParticipantType.Owner;
    case UserPropertyType.Referent:
      return orgType === OrgType.RenovationOperator
        ? ParticipantType.RenovationOperator
        : ParticipantType.PropertyManager;
    default:
      return ParticipantType.Tenant;
  }
}

export function getDisplayParticipantTypeFromUserProperty(
  userPropertyType: UserPropertyType | undefined,
  orgType?: OrgType,
): DisplayParticipantType {
  switch (userPropertyType) {
    case UserPropertyType.Owner:
      return DisplayParticipantType.Owner;
    case UserPropertyType.Referent:
      if (!orgType) {
        return DisplayParticipantType.Organization;
      }

      return orgType === OrgType.PropertyManager
        ? DisplayParticipantType.PropertyManager
        : DisplayParticipantType.RenovationOperator;
    case UserPropertyType.Tenant:
      return DisplayParticipantType.Tenant;
    default:
      return DisplayParticipantType.EmptyTenant;
  }
}

export function getDisplayParticipantTypeFromParticipant(participantType: ParticipantType): DisplayParticipantType {
  switch (participantType) {
    case ParticipantType.Owner:
      return DisplayParticipantType.Owner;
    case ParticipantType.RenovationOperator:
      return DisplayParticipantType.RenovationOperator;
    case ParticipantType.PropertyManager:
      return DisplayParticipantType.PropertyManager;
    case ParticipantType.Tenant:
      return DisplayParticipantType.Tenant;
    default:
      return DisplayParticipantType.EmptyTenant;
  }
}

export function getUserPropertyType(participantType: ParticipantType): UserPropertyType {
  switch (participantType) {
    case ParticipantType.Owner:
      return UserPropertyType.Owner;
    case ParticipantType.Tenant:
      return UserPropertyType.Tenant;
    default:
      return UserPropertyType.Referent;
  }
}

export function mapPropertyFormGroupToProperty(formGroup: FormGroup): Partial<Property> {
  const mappedProperty: Partial<Property> = formGroup.value as any;

  mapSecondaryFormGroup(mappedProperty, formGroup.get('generalCharacteristics') as FormGroup);
  mapSecondaryFormGroup(mappedProperty, formGroup.get('coproperty') as FormGroup);
  mapSecondaryFormGroup(mappedProperty, formGroup.get('occupancy') as FormGroup);
  mapSecondaryFormGroup(mappedProperty, formGroup.get('propertyArea') as FormGroup);
  mapSecondaryFormGroup(mappedProperty, formGroup.get('isolationJoineryQuality') as FormGroup);
  mapSecondaryFormGroup(mappedProperty, formGroup.get('energyUsage') as FormGroup);
  mapSecondaryFormGroup(mappedProperty, formGroup.get('pdl') as FormGroup);
  mapSecondaryFormGroup(mappedProperty, formGroup.get('sharedNotes') as FormGroup);

  mappedProperty['digicode'] = formGroup.get('address')?.get('digicode')?.value;
  mappedProperty['interphone'] = formGroup.get('address')?.get('interphone')?.value;

  return mappedProperty;
}

function mapSecondaryFormGroup(mappedProperty: Partial<Property>, formGroup: FormGroup): void {
  for (const valueKey in formGroup.controls) {
    mappedProperty[valueKey as keyof Property] = formGroup.get(valueKey)?.value;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createPropertyFormGroup(formBuilder: FormBuilder) {
  return formBuilder.group({
    id: [{ value: '', disabled: true }],
    customName: '',
    address: formBuilder.group({
      label: [{ value: '', disabled: true }],
      addition: [''],
      digicode: [''],
      interphone: [''],
    }),
    generalCharacteristics: formBuilder.group({
      housingType: undefined,
      housingSize: undefined,
      buildingHomes: undefined,
      constructionYear: [0, [Validators.pattern('^[0-9]+$')]],
      constructionPeriod: undefined,
      sharedWalls: undefined,
    }),
    coproperty: formBuilder.group({
      copropertyName: '',
      copropertyRegistrationId: '',
      unionName: '',
      unionSiret: '',
      copropertyLivingLots: undefined,
      copropertyTotalLots: undefined,
    }),
    occupancy: formBuilder.group({
      occupancy: undefined,
      rentalType: undefined,
      occupancyTenantEntryDate: new Date(),
      leaseEndDate: new Date(),
      propertyManagerType: undefined,
      adultOccupants: undefined,
      minorOccupants: undefined,
    }),
    propertyArea: formBuilder.group({
      livingArea: undefined,
      heatedArea: undefined,
      cooledArea: undefined,
      outbuildingArea: undefined,
      levels: undefined,
      livingLots: undefined,
      totalLots: undefined,
    }),
    dpe: formBuilder.group({
      legalId: [{ value: '', disabled: true }, [Validators.maxLength(13), Validators.minLength(13)]],
      date: new Date(),
      energyConsumption: undefined,
      gasEmission: undefined,
      energyTag: undefined,
      gasTag: undefined,
      updateReason: undefined,
      updateReasonDate: undefined,
    }),
    isolationJoineryQuality: formBuilder.group({
      wallInsulation: undefined,
      lowFloorType: undefined,
      lowFloorInsulation: undefined,
      highFloorType: undefined,
      highFloorInsulation: undefined,
      joineryQuality: undefined,
    }),
    energyUsage: formBuilder.group({
      mainEnergyType: undefined,
      mainEnergyDevice: undefined,
      mainEnergyTimeline: undefined,
      subEnergyType: undefined,
      subEnergyDevice: undefined,
      subEnergyTimeline: undefined,
      waterEnergyType: undefined,
      waterEnergyDevice: undefined,
      waterEnergyTimeline: undefined,
      waterDeviceVolume: 0,
      ventilationType: undefined,
      airConditioningType: undefined,
      thermostat: undefined,
      bulbType: undefined,
      cooktopType: undefined,
      ovenType: undefined,
    }),
    pdl: formBuilder.group({
      energyMeterId: ['', [Validators.maxLength(14), Validators.minLength(14)]],
      gasMeterId: ['', [Validators.maxLength(14), Validators.minLength(14)]],
    }),
    tenantOccupancyHabits: formBuilder.group({
      heatingUpkeep: undefined,
      ventilationUpkeep: undefined,
      airInletUpkeep: undefined,
      naturalAiring: undefined,
      usualHeatingInstructionsTemp: undefined,
      usualHeatingInstructionsHours: [{ value: 112, disabled: true }],
      nightHeatingInstructionsTemp: undefined,
      nightHeatingInstructionsHours: [56, [Validators.min(0), Validators.max(168)]],
      absenceHeatingInstructionsTemp: undefined,
      absenceHeatingInstructionsHours: [0, [Validators.min(0), Validators.max(168)]],
      weeklyShowers: undefined,
      weeklyBaths: undefined,
    }),
    ownerOccupancyHabits: formBuilder.group({
      heatingUpkeep: undefined,
      ventilationUpkeep: undefined,
      airInletUpkeep: undefined,
      naturalAiring: undefined,
      usualHeatingInstructionsTemp: undefined,
      usualHeatingInstructionsHours: [{ value: 112, disabled: true }],
      nightHeatingInstructionsTemp: undefined,
      nightHeatingInstructionsHours: [56, [Validators.min(0), Validators.max(168)]],
      absenceHeatingInstructionsTemp: undefined,
      absenceHeatingInstructionsHours: [0, [Validators.min(0), Validators.max(168)]],
      weeklyShowers: undefined,
      weeklyBaths: undefined,
    }),
    sharedNotes: formBuilder.group({
      sharedNotes: '',
    }),
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function patchPropertyFormGroup(propertyFormGroup: any, property: Property) {
  propertyFormGroup.patchValue({
    id: property.id,
    customName: property.loggedUserProperty?.customName || '',
    address: {
      ...property.address,
      digicode: property.digicode,
      interphone: property.interphone,
    },
    dpe: {
      ...property.dpe,
    },
    generalCharacteristics: {
      housingType: property.housingType,
      housingSize: property.housingSize,
      buildingHomes: property.buildingHomes,
      constructionYear: property.constructionYear,
      constructionPeriod: property.constructionPeriod,
      sharedWalls: property.sharedWalls,
    },
    coproperty: {
      copropertyName: property.copropertyName,
      copropertyRegistrationId: property.copropertyRegistrationId,
      unionName: property.unionName,
      unionSiret: property.unionSiret,
      copropertyLivingLots: property.copropertyLivingLots,
      copropertyTotalLots: property.copropertyTotalLots,
    },
    occupancy: {
      occupancy: property.occupancy,
      rentalType: property.rentalType,
      occupancyTenantEntryDate: property.occupancyTenantEntryDate,
      leaseEndDate: property.leaseEndDate,
      propertyManagerType: property.propertyManagerType,
      adultOccupants: property.adultOccupants,
      minorOccupants: property.minorOccupants,
    },
    propertyArea: {
      livingArea: property.livingArea,
      heatedArea: property.heatedArea,
      cooledArea: property.cooledArea,
      outbuildingArea: property.outbuildingArea,
      levels: property.levels,
      livingLots: property.livingLots,
      totalLots: property.totalLots,
    },
    isolationJoineryQuality: {
      wallInsulation: property.wallInsulation,
      lowFloorType: property.lowFloorType,
      lowFloorInsulation: property.lowFloorInsulation,
      highFloorType: property.highFloorType,
      highFloorInsulation: property.highFloorInsulation,
      joineryQuality: property.joineryQuality,
    },
    energyUsage: {
      mainEnergyType: property.mainEnergyType,
      mainEnergyDevice: property.mainEnergyDevice,
      mainEnergyTimeline: property.mainEnergyTimeline,
      subEnergyType: property.subEnergyType,
      subEnergyDevice: property.subEnergyDevice,
      subEnergyTimeline: property.subEnergyTimeline,
      waterEnergyType: property.waterEnergyType,
      waterEnergyDevice: property.waterEnergyDevice,
      waterEnergyTimeline: property.waterEnergyTimeline,
      waterDeviceVolume: property.waterDeviceVolume,
      ventilationType: property.ventilationType,
      airConditioningType: property.airConditioningType,
      thermostat: property.thermostat,
      bulbType: property.bulbType,
      cooktopType: property.cooktopType,
      ovenType: property.ovenType,
    },
    pdl: {
      energyMeterId: property.electricityState.meterId,
      gasMeterId: property.gasState.meterId,
    },
    tenantOccupancyHabits: {
      heatingUpkeep: property.tenantOccupancyHabits?.heatingUpkeep,
      ventilationUpkeep: property.tenantOccupancyHabits?.ventilationUpkeep,
      airInletUpkeep: property.tenantOccupancyHabits?.airInletUpkeep,
      naturalAiring: property.tenantOccupancyHabits?.naturalAiring,
      usualHeatingInstructionsTemp: property.tenantOccupancyHabits?.usualHeatingInstructionsTemp,
      usualHeatingInstructionsHours: property.tenantOccupancyHabits?.usualHeatingInstructionsHours ?? 112,
      nightHeatingInstructionsTemp: property.tenantOccupancyHabits?.nightHeatingInstructionsTemp,
      nightHeatingInstructionsHours: property.tenantOccupancyHabits?.nightHeatingInstructionsHours ?? 56,
      absenceHeatingInstructionsTemp: property.tenantOccupancyHabits?.absenceHeatingInstructionsTemp,
      absenceHeatingInstructionsHours: property.tenantOccupancyHabits?.absenceHeatingInstructionsHours ?? 0,
      weeklyShowers: property.tenantOccupancyHabits?.weeklyShowers,
      weeklyBaths: property.tenantOccupancyHabits?.weeklyBaths,
    },
    ownerOccupancyHabits: {
      heatingUpkeep: property.ownerOccupancyHabits?.heatingUpkeep,
      ventilationUpkeep: property.ownerOccupancyHabits?.ventilationUpkeep,
      airInletUpkeep: property.ownerOccupancyHabits?.airInletUpkeep,
      naturalAiring: property.ownerOccupancyHabits?.naturalAiring,
      usualHeatingInstructionsTemp: property.ownerOccupancyHabits?.usualHeatingInstructionsTemp,
      usualHeatingInstructionsHours: property.ownerOccupancyHabits?.usualHeatingInstructionsHours ?? 112,
      nightHeatingInstructionsTemp: property.ownerOccupancyHabits?.nightHeatingInstructionsTemp,
      nightHeatingInstructionsHours: property.ownerOccupancyHabits?.nightHeatingInstructionsHours ?? 56,
      absenceHeatingInstructionsTemp: property.ownerOccupancyHabits?.absenceHeatingInstructionsTemp,
      absenceHeatingInstructionsHours: property.ownerOccupancyHabits?.absenceHeatingInstructionsHours ?? 0,
      weeklyShowers: property.ownerOccupancyHabits?.weeklyShowers,
      weeklyBaths: property.ownerOccupancyHabits?.weeklyBaths,
    },
    sharedNotes: {
      sharedNotes: property.sharedNotes,
    },
  });

  if (property.dpe?.gasTag) {
    propertyFormGroup.controls.dpe.controls.gasTag.patchValue(property.dpe.gasTag);
  }

  if (property.dpe?.energyTag) {
    propertyFormGroup.controls.dpe.controls.energyTag.patchValue(property.dpe.energyTag);
  }

  return propertyFormGroup;
}

export function isOrgAnyAdmin(user: User, orgId?: number): boolean {
  if (!orgId) {
    return false;
  }

  const role = user.userOrgs?.find((uo) => uo.orgId === orgId)?.role;

  return !!role && [OrgRole.Admin, OrgRole.MainAdmin].includes(role);
}
