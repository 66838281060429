export enum UserPropertyType {
  Owner = 1,
  Tenant = 2,
  Referent = 3, // This one should only exist when there is also an org
}

export enum EnergyTag {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  E = 5,
  F = 6,
  G = 7,
}

export enum WorkStatus {
  Thinking = 1,
  Waiting = 2,
  InProgress = 3,
  Achieved = 4,
  Discarded = 5,
}

export enum OrgRole {
  Admin = 1,
  Regular = 2,
  MainAdmin = 3,
}

export const ORG_ROLE_MAP = new Map<OrgRole, string>([
  [OrgRole.MainAdmin, 'Administrateur principal'],
  [OrgRole.Admin, 'Administrateur'],
  [OrgRole.Regular, 'Standard'],
]);

export const USER_PROPERTY_TYPE_MAP = new Map<UserPropertyType, string>([
  [UserPropertyType.Owner, 'Propriétaire'],
  [UserPropertyType.Tenant, 'Locataire'],
  [UserPropertyType.Referent, 'Référent'],
]);

export enum AccountType {
  PhysicalPerson = 1,
  LegalPerson = 2,
}

export const ACCOUNT_TYPE_MAP = new Map<AccountType, string>([
  [AccountType.PhysicalPerson, 'Personne physique'],
  [AccountType.LegalPerson, 'Personne morale'],
]);

export const MONTH_LABEL_MAP = new Map<number, string>([
  [0, 'Janv.'],
  [1, 'Févr.'],
  [2, 'Mars'],
  [3, 'Avr.'],
  [4, 'Mai'],
  [5, 'Juin'],
  [6, 'Juil.'],
  [7, 'Août'],
  [8, 'Sept.'],
  [9, 'Oct.'],
  [10, 'Nov.'],
  [11, 'Déc.'],
]);

export enum AssociatedOrgType {
  ParentOrg = 1,
  ProgramManager = 2,
}

export enum VatRate {
  None = 1,
  Special = 2,
  Reduced = 3,
  Intermediate = 4,
  Normal = 5,
  Overseas = 6,
}

export const VAT_RATE_MAP = new Map<VatRate, string>([
  [VatRate.None, 'Non soumis'],
  [VatRate.Special, '2,1%'],
  [VatRate.Reduced, '5,5%'],
  [VatRate.Overseas, '8,5%'],
  [VatRate.Intermediate, '10%'],
  [VatRate.Normal, '20%'],
]);

export enum DeleteReason {
  Revocation = 1,
  Switch = 2,
  OccupancyChange = 3,
}
